import React, { useState, useEffect } from "react";
import MainScreen from "./MainScreen/MainScreen";
import * as Constants from "./Constants";
import * as Utilities from "./Utilities";
import axios from "axios";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useSiteContext } from "./Context.js";
import HQLogoSVG from "./Generic/HQLogoSVG";
import ErrorBoundary from "./Generic/ErrorBoundary";
import { useNavigate } from "react-router-dom";

function LoginScreen(props) {
  const cookies = new Cookies();
  const [loggedIn, SetLoggedIn] = useState(false);
  const [username, SetUsername] = useState(cookies.get("username"));
  const [password, SetPassword] = useState("");
  const [passwordVisible, SetPasswordVisible] = useState(false);
  const [remember, SetRemember] = useState(cookies.get("remember") === "true");
  const [userInfo, SetUserInfo] = useState(null);
  const [sessionCookie, SetSessionCookie] = useState(cookies.get("session"));
  const [newPassword, SetNewPassword] = useState("");
  const [confirmNewPassword, SetConfirmNewPassword] = useState("");
  const [needsPasswordChange, SetNeedsPasswordChange] = useState(false);
  const [pathArray, SetPathArray] = useState(useLocation().pathname.split("/"));
  const location = useLocation();
  const {
    alertArray,
    AddData,
    AddAlert,
    RemoveAlert,
    loggedInUser,
    SetMainUser,
    impersonatedUser,
    SetImpersonatedUser,
    ShowLoadingScreen,
    fieldCustomizations,
    RebuildTooltips,
  } = useSiteContext();

  const navigate = useNavigate();

  function Login() {
    var formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);
    if (remember) {
      cookies.set("username", username, { path: "/" });
      cookies.set("remember", remember, { path: "/" });
    } else {
      cookies.remove("username", { path: "/" });
      cookies.remove("remember", { path: "/" });
    }
    axios({
      method: "post",
      url: Constants.API_URL + "Login" + Constants.API_KEY,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      loadingScreen: "main",
    })
      .then((response) => {
        if (response.data !== "Failed") {
          if (response.data === "Change Password Needed") {
            SetNeedsPasswordChange(true);
            AddAlert("notification", "Password change required");
          } else {
            SetUserInfo(response.data);
            SetMainUser(response.data);
            SetPasswordVisible(false);
            SetLoggedIn(true);
            SetPassword("");
            SetNewPassword("");
            SetConfirmNewPassword("");
            AddAlert(
              "notification",
              "Welcome, " + response.data.firstName + "."
            );
          }
        } else {
          AddAlert(
            "error",
            "The username or password do not match any accounts on file. Please try again."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function UpdatePassword() {
    if (Utilities.ValidateField("Password", newPassword) && PasswordsMatch()) {
      var formData = new FormData();
      formData.append("email", username);
      formData.append("password", password);
      formData.append("newPassword", newPassword);
      axios({
        method: "post",
        url: Constants.API_URL + "ChangePassword" + Constants.API_KEY,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        loadingScreen: "main",
      })
        .then((response) => {
          if (response.data?.sessionToken != null) {
            SetNeedsPasswordChange(false);
            SetPassword("");
            SetNewPassword("");
            SetConfirmNewPassword("");
            AddAlert("notification", "Changed password successfully.");
            LoginBySession(response.data.sessionToken);
          } else {
            AddAlert(
              "error",
              "The orginal username or password are incorrect."
            );
          }
        })
        .catch((error) => {
          AddAlert(
            "error",
            "Could not connect to the server. Please try again later."
          );
        });
    } else {
      AddAlert(
        "error",
        "Please make sure password requirements are met as well as confirm password matches the new password."
      );
      SetConfirmNewPassword("");
    }
  }

  function LoginBySession(sessionKey) {
    var formData = new FormData();
    formData.append("sessionToken", sessionKey ?? sessionCookie);
    axios({
      method: "post",
      url: Constants.API_URL + "LoginBySession" + Constants.API_KEY,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      loadingScreen: "main",
    })
      .then((response) => {
        if (response.data !== "Failed") {
          SetUserInfo(response.data);
          SetMainUser(response.data);
          SetPasswordVisible(false);
          SetLoggedIn(true);
          SetPassword("");
          SetNewPassword("");
          SetConfirmNewPassword("");
          AddAlert(
            "notification",
            "Welcome back, " + response.data.firstName + "."
          );
          // setTimeout(() => {
          //   AddAlert("error", "Logged in Error Test.");
          // }, 100);
        } else {
          cookies.remove("session", { path: "/" });
          SetSessionCookie(null);
          AddAlert(
            "notification",
            "Your session has expired. Please sign in again."
          );
        }
      })
      .catch((error) => {
        AddAlert(
          "error",
          "Could not connect to server. Please try again later."
        );
      });
  }

  function Logout() {
    SetLoggedIn(false);
    SetUsername(cookies.get("username"));
    SetPassword("");
    SetUserInfo(null);
    SetMainUser(null);
    SetImpersonatedUser(null);
    SetSessionCookie(null);
    cookies.remove("session", { path: "/" });
  }

  function ImpersonateUser(userId) {
    if (userId === null) {
      SetImpersonatedUser(null);
      return;
    }
    var formData = new FormData();
    formData.append("userToImpersonate", userId);
    axios({
      method: "post",
      url: Constants.API_URL + "Impersonate" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        UserId: userInfo.id,
      },
      data: formData,
    })
      .then((response) => {
        if (response.data !== "Failed") {
          SetImpersonatedUser(response.data);
        } else {
          AddAlert(
            "error",
            "Could not impersonate user. Please try again later."
          );
        }
      })
      .catch((error) => {
        AddAlert(
          "error",
          "Could not connect to server. Please try again later."
        );
      });
  }

  function ChangeUsername(event) {
    SetUsername(event.target.value);
  }

  function ChangePassword(event) {
    SetPassword(event.target.value);
  }

  function ChangeRemember(event) {
    SetRemember(event.target.checked);
  }

  function ChangeNewPassword(event) {
    SetNewPassword(event.target.value);
  }

  function ChangeConfirmNewPassword(event) {
    SetConfirmNewPassword(event.target.value);
  }

  function HandleEnter(event) {
    if (event.key === "Enter") {
      SubmissionType();
    }
  }

  function TogglePasswordVisibility() {
    SetPasswordVisible(!passwordVisible);
  }

  function SubmissionType() {
    if (needsPasswordChange) {
      UpdatePassword();
    } else {
      Login();
    }
  }

  function PasswordsMatch() {
    return newPassword === confirmNewPassword;
  }

  useEffect(() => {
    if (sessionCookie != null) {
      LoginBySession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo != null) {
      cookies.set("session", userInfo.sessionToken, {
        path: "/",
        maxAge: 2592000,
      });
      SetSessionCookie(userInfo.sessionToken);
    }
    let checkForSessionCookieInterval = setInterval(() => {
      let currentSessionCookie = cookies.get("session");
      if (
        userInfo != null &&
        (currentSessionCookie == null || currentSessionCookie === "")
      ) {
        Logout();
      }
    }, 5000);

    return () => {
      clearInterval(checkForSessionCookieInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (userInfo != null) {
      ShowLoadingScreen(true);
      SetLoggedIn(false);
      setTimeout(() => {
        SetPasswordVisible(false);
        SetLoggedIn(true);
      }, 50);
      setTimeout(() => {
        navigate("/");
      }, 200);
      setTimeout(() => {
        ShowLoadingScreen(false);
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatedUser]);

  useEffect(() => {
    SetPathArray(location.pathname.split("/"));
  }, [location.pathname]);

  return (
    <div className="screenContainer">
      {!loggedIn ? (
        <div
          className={
            needsPasswordChange
              ? "loginContainer changePassword"
              : "loginContainer"
          }
        >
          <div className="loginHeaderIcon">
            <div className="loginCircle">
              <HQLogoSVG full={false} alternate={true} />
            </div>
            <HQLogoSVG full={true} alternate={true} />
          </div>
          <div className="loginInputsContainer">
            <div className="loginInputContainer">
              <label className="loginInputLabel">Username:</label>
              <div className="loginInputPasswordContainer">
                <input
                  className="loginInput"
                  type="text"
                  value={username}
                  onChange={ChangeUsername}
                />
              </div>
            </div>
            {!needsPasswordChange ? (
              <>
                <div className="loginInputContainer">
                  <label className="loginInputLabel">Password:</label>
                  <div className="loginInputPasswordContainer">
                    <input
                      className="loginInput"
                      type={passwordVisible ? "text" : "password"}
                      value={password}
                      onChange={ChangePassword}
                      onKeyDown={(event) => {
                        HandleEnter(event);
                      }}
                    />
                    <Icon
                      data-tip="View/Hide Password"
                      icon={
                        passwordVisible
                          ? "akar-icons:eye-open"
                          : "akar-icons:eye-slashed"
                      }
                      className="loginPasswordEye"
                      onClick={TogglePasswordVisibility}
                    />
                  </div>
                </div>
                <label className="loginCheckboxContainer">
                  <input
                    type="checkbox"
                    onChange={ChangeRemember}
                    checked={remember}
                  />
                  <span className="loginCheckboxCheckmark"></span>
                  Remember Me
                </label>
              </>
            ) : (
              <>
                <div className="loginInputContainer">
                  <label className="loginInputLabel">New Password:</label>
                  <div className="loginInputPasswordContainer">
                    <input
                      className={
                        Utilities.ValidateField("Password", newPassword)
                          ? "loginInput"
                          : "loginInput inputError"
                      }
                      data-tip={
                        Utilities.ValidateField("Password", newPassword)
                          ? ""
                          : "Password must meet the following criteria:<br>- At Least 8 Characters<br>- One Number<br>- One Capital Letter<br>- One Lower-Case Letter<br>- One Special Character from the below:<br>+-_!@#$%^&*.,/\\?"
                      }
                      data-multiline={true}
                      data-class="tooltipError"
                      data-effect="solid"
                      type={passwordVisible ? "text" : "password"}
                      value={newPassword}
                      onChange={ChangeNewPassword}
                      onKeyDown={(event) => {
                        HandleEnter(event);
                      }}
                    />
                    <Icon
                      icon={
                        passwordVisible
                          ? "akar-icons:eye-open"
                          : "akar-icons:eye-slashed"
                      }
                      className="loginPasswordEye"
                      data-tip="View/Hide Password"
                      onClick={TogglePasswordVisibility}
                    />
                  </div>
                </div>
                <div className="loginInputContainer">
                  <label className="loginInputLabel">Confirm Password:</label>
                  <div className="loginInputPasswordContainer">
                    <input
                      className={
                        PasswordsMatch()
                          ? "loginInput"
                          : "loginInput inputError"
                      }
                      data-tip={
                        PasswordsMatch()
                          ? RebuildTooltips()
                          : "Passwords do not match."
                      }
                      data-class="tooltipError"
                      data-effect="solid"
                      type={passwordVisible ? "text" : "password"}
                      value={confirmNewPassword}
                      onChange={ChangeConfirmNewPassword}
                      onKeyDown={(event) => {
                        HandleEnter(event);
                      }}
                    />
                    <Icon
                      icon={
                        passwordVisible
                          ? "akar-icons:eye-open"
                          : "akar-icons:eye-slashed"
                      }
                      className="loginPasswordEye"
                      data-tip="View/Hide Password"
                      onClick={TogglePasswordVisibility}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <button className="loginButton" onClick={SubmissionType}>
            Sign In
          </button>
        </div>
      ) : fieldCustomizations.loadedFromLogin && loggedInUser != null ? (
        <ErrorBoundary loggedInUser={loggedInUser} AddData={AddData}>
          <MainScreen
            loggedInUser={
              impersonatedUser != null ? impersonatedUser : userInfo
            }
            impersonating={impersonatedUser != null}
            ImpersonateUser={ImpersonateUser}
            path={pathArray}
            Logout={Logout}
          />
        </ErrorBoundary>
      ) : (
        <div>Loading...</div>
      )}
      <div id="loadingScreen" className="loadingScreen">
        <div className="loadingScreenIcon spinning" alt="Loading Animation">
          <HQLogoSVG full={false} />
        </div>
        <div className="loadingScreenText">Loading ...</div>
      </div>
      <div id="alertBox" className="alertBox">
        {alertArray.map((alert) => {
          return (
            <div
              key={alert.id}
              id={"alert-" + alert.id}
              className={
                alert.type === "error"
                  ? "alertBoxItem errorAlert alert-active"
                  : alert.message.length > 100
                  ? "alertBoxItem alert-active longAlert"
                  : "alertBoxItem alert-active"
              }
              onClick={() => {
                RemoveAlert(alert.id);
              }}
            >
              <div className="alertBoxItemMessage">{alert.message}</div>
              <div className="alertBoxItemClose">X</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LoginScreen;
