import React from "react";
import { useSiteContext } from "./Context";

function ColorScheme() {
  const { colorScheme, darkMode } = useSiteContext();

  const colors = new Colors({ scheme: colorScheme, darkMode: darkMode });
  // console.log(colors.ListAllColors());

  function ProcessHexToRGB(hex) {
    if (hex.includes("var(--")) {
      hex = hex.split(")")[0];
      hex += "-rgb)";
      return hex;
    }
    if (hex.length === 7) {
      return hex
        .match(/[A-Za-z0-9]{2}/g)
        .map((val) => parseInt(val, 16))
        .join(", ");
    } else {
      return hex
        .match(/[A-Za-z0-9]{1}/g)
        .map((val) => parseInt(val + val, 16))
        .join(", ");
    }
  }

  return (
    <style>
      {`body {
${colors.baseColors
  .map(
    (color, index) => `--BaseSiteColor${index + 1}: ${color};
  --BaseSiteColor${index + 1}-rgb: ${ProcessHexToRGB(color)};`
  )
  .join("\n")}
${colors.FONT_COLORS.map(
  (color, index) => `--SiteFontColor${index + 1}: ${color};
  --SiteFontColor${index + 1}-rgb: ${ProcessHexToRGB(color)};`
).join("\n")}
${colors.BACKGROUND_COLORS.map(
  (color, index) => `--SiteBackgroundColor${index + 1}: ${color};
  --SiteBackgroundColor${index + 1}-rgb: ${ProcessHexToRGB(color)};`
).join("\n")}
${colors.LOGO_COLORS.map(
  (color, index) => `--SiteLogoColor${index + 1}: ${color};
  --SiteLogoColor${index + 1}-rgb: ${ProcessHexToRGB(color)};`
).join("\n")}
${colors.GANTT_COLORS.map(
  (color, index) => `--GanttColor${index + 1}: ${color};
  --GanttColor${index + 1}-rgb: ${ProcessHexToRGB(color)};`
).join("\n")}
${colors.GRADIENTS.map(
  (color, index) => `--Gradient${index + 1}: ${color};`
).join("\n")}
${colors.OUTLINES.map(
  (color, index) => `--Outline${index + 1}: ${color};
  --Outline${index + 1}-rgb: ${ProcessHexToRGB(color)};`
).join("\n")}
}`}
      ${darkMode ? "input { color-scheme: dark; }" : ""}
    </style>
  );
}

export default ColorScheme;

class Colors {
  constructor({ scheme, darkMode }) {
    let combinedScheme = scheme + "-" + (darkMode ? "dark" : "light");
    this.scheme = scheme;
    this.darkMode = darkMode;
    this.baseColors = this.GetBaseColors(combinedScheme);
    this.fontColors = this.FONT_COLORS;
    if (darkMode) {
      this.fontColors[4] = "var(--BaseSiteColor1)";
    }
  }

  // Color Schema
  // 0 - Primary
  // 1 - Secondary
  // 2 - Tertiary
  // 3 - Base
  // 4 - Error
  // 5 - Gantt Variant 2
  // 6 - Gantt Variant 3
  // 7 - Gantt Variant 4
  // 8 - Gantt Variant 5

  GREEN_LIGHT_BASE_COLORS = [
    "#314323",
    "#e0f2d3",
    "#3e8531",
    "#e5e5e5",
    "#b81800",
    "#352343",
    "#414323",
    "#234335",
    "#233143",
  ];
  GREEN_DARK_BASE_COLORS = [
    "#abc39e",
    "#1A2711",
    "#283A1C",
    "#121212",
    "#7C2619",
    "#b69ec3",
    "#bdc39e",
    "#9ec3b6",
    "#9eabc3",
  ];
  CLASSIC_LIGHT_BASE_COLORS = [
    "#21432e",
    "#c3a57f",
    "#254321",
    "#e5e5e5",
    "#b81800",
    "#352343",
    "#414323",
    "#234335",
    "#233143",
  ];
  CLASSIC_DARK_BASE_COLORS = [
    "#abc39e",
    "#1A2711",
    "#6d501d",
    "#121212",
    "#7C2619",
    "#b69ec3",
    "#bdc39e",
    "#9ec3b6",
    "#9eabc3",
  ];
  BLUE_LIGHT_BASE_COLORS = [
    "#38596b",
    "#d4e8f3",
    "#34568e",
    "#e5e5e5",
    "#b81800",
    "#6b4a38",
    "#386b63",
    "#4a386b",
    "#6b3859",
  ];
  BLUE_DARK_BASE_COLORS = [
    "#A9BDE2",
    "#242F57",
    "#2B364C",
    "#121212",
    "#7C2619",
    "#e2cea9",
    "#a9d9e2",
    "#cea9e2",
    "#e2a9bd",
  ];
  ORANGE_LIGHT_BASE_COLORS = [
    "#4b2407",
    "#ecd3c8",
    "#b05610",
    "#e5e5e5",
    "#aa0000",
    "#072e4b",
    "#4b070c",
    "#4b4607",
    "#074b24",
  ];
  ORANGE_DARK_BASE_COLORS = [
    "#F7D3AB",
    "#341E17",
    "#442A21",
    "#121212",
    "#9F1D16",
    "#abcef7",
    "#f7aeab",
    "#f4f7ab",
    "#abf7d4",
  ];

  FONT_COLORS = [
    "var(--BaseSiteColor1)",
    "var(--BaseSiteColor4)",
    "var(--BaseSiteColor5)",
    "var(--BaseSiteColor1)",
    "var(--BaseSiteColor2)",
  ];

  BACKGROUND_COLORS = ["#e27969", "var(--BaseSiteColor4)"];

  GANTT_COLORS = [
    "var(--BaseSiteColor3)",
    "var(--BaseSiteColor1)",
    "var(--BaseSiteColor6)",
    "var(--BaseSiteColor7)",
    "var(--BaseSiteColor8)",
    "var(--BaseSiteColor5)",
    "var(--BaseSiteColor9)",
  ];

  LOGO_COLORS = [
    "var(--BaseSiteColor1)",
    "var(--BaseSiteColor2)",
    "var(--BaseSiteColor4)",
  ];

  GRADIENTS = [
    "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.075) 100%)",
    "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.15) 100%)",
    "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.25) 100%)",
    "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.35) 100%)",
    "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.45) 100%)",
    "linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.15) 100%)",
  ];

  OUTLINES = ["#000000", "#bbbbbb"];

  GetBaseColors(scheme) {
    let result = [];
    switch (scheme) {
      case "green-light":
        result = this.GREEN_LIGHT_BASE_COLORS;
        break;
      case "green-dark":
        result = this.GREEN_DARK_BASE_COLORS;
        break;
      case "blue-light":
        result = this.BLUE_LIGHT_BASE_COLORS;
        break;
      case "blue-dark":
        result = this.BLUE_DARK_BASE_COLORS;
        break;
      case "orange-light":
        result = this.ORANGE_LIGHT_BASE_COLORS;
        break;
      case "orange-dark":
        result = this.ORANGE_DARK_BASE_COLORS;
        break;
      case "classic-light":
        result = this.CLASSIC_LIGHT_BASE_COLORS;
        break;
      case "classic-dark":
        result = this.CLASSIC_DARK_BASE_COLORS;
        break;
      default:
        result = this.GREEN_LIGHT_BASE_COLORS;
        break;
    }
    return result;
  }

  ListAllColors() {
    let resultList = [];

    this.baseColors.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    this.FONT_COLORS.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    this.BACKGROUND_COLORS.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    this.LOGO_COLORS.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    this.GANTT_COLORS.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    this.OUTLINES.forEach((color, index) => {
      if (!resultList.includes(color)) {
        resultList.push(color);
      }
    });
    resultList = resultList.filter(
      (color) => color.includes("var(--") === false
    );
    return resultList;
  }
}
